var config = {
  crash_port: 4202,
  chat_port: 4204,
  main_port: 3001,
  
  // base_domain: 'http://localhost',
  base_domain: 'https://thegamblersden.co.za',
  default_avatar_img: '{base_domain}/img/uploads/avatar/placeholder.png'
}
module.exports = config
